import React from 'react';

import { DeleteTwoTone } from '@ant-design/icons';
import { Button, Layout, Row, Space, Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { CartItemSchema } from '@app/models/products';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { HeaderTitle } from '@components/HeaderTitle';
import { Paper } from '@components/Paper';

import { useGetCart, useGetCatalogQuery, usePostCartRemoveItems, usePostUpdateCartItem } from '../../../../hooks';

import { AddProductModal } from './components/AddProductModal';
import { OrderForm } from './components/OrderForm';

import { columns } from './data/constants';

export const Cart: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const nomenclatureCartT = useTranslation(NSKeys.nomenclatureCart);
  const commonT = useTranslation(NSKeys.common);
  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);

  const { data: cart, isLoading: isCartLoading } = useGetCart(id || '', true);
  const { data: catalog, isLoading: isCatalogLoading } = useGetCatalogQuery(id || '');
  const { mutateAsync: removeItem, isLoading: isItemRemoving } = usePostCartRemoveItems();
  const { mutateAsync: updateCart } = usePostUpdateCartItem();

  const handleDeleteClick = async () => {
    await removeItem({ cart_uid: cart?.uid || '', catalog_uid: id || '', data: { item_uids: selectedRows } });
    setSelectedRows([]);
  };

  const handleInputChange = async (value: number, cartItem: CartItemSchema) => {
    // if (value === 0) {
    //   await removeItem({ cart_uid: cart?.uid || '', catalog_uid: id || '', data: { item_uids: [cartItem.uid] } });
    // }
    if (value > 0) {
      await updateCart({
        catalog_uid: id || '',
        data: { product_uid: cartItem.product.uid, quantity: value },
      });
    }
  };

  return (
    <Layout>
      <Layout.Header>
        <Breadcrumbs apiTitles={[{ id: ':catalogId', value: catalog?.name }]} />
        <Row justify="space-between" align="middle">
          <HeaderTitle
            loading={isCartLoading || isCatalogLoading || isItemRemoving}
            title={nomenclatureCartT.t('cart')}
            titleSpan={cart?.items_total}
            onClick={() => navigate(`/dashboard/catalogs/${id}`)}
          />
        </Row>
      </Layout.Header>
      <Layout.Content>
        <Paper padding="24px" style={{ margin: 24 }}>
          <React.Fragment>
            <Row justify="space-between" align="middle" style={{ height: '64px' }}>
              <Typography.Title level={5} style={{ margin: 0 }}>
                {nomenclatureCartT.t('totalAmount', { count: cart?.total?.quantity })}
              </Typography.Title>
              <Space>
                <AddProductModal cartProducts={cart?.items?.map((el) => el.product.uid)} catalogUid={id || ''} />
                {!!selectedRows.length && (
                  <Button type="link" icon={<DeleteTwoTone />} onClick={handleDeleteClick}>
                    {commonT.t('removeSelected')}
                  </Button>
                )}
              </Space>
            </Row>
            <Table<CartItemSchema>
              loading={isCartLoading}
              className="tableClassname"
              columns={columns(nomenclatureCartT.t, handleInputChange)}
              dataSource={cart?.items || undefined}
              pagination={false}
              rowKey="uid"
              scroll={{ x: 'max-content' }}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: selectedRows,
                onChange: (selectedRowKeys) => {
                  setSelectedRows(selectedRowKeys as any);
                },
              }}
              summary={() => (
                <Table.Summary>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0} colSpan={4}>
                      {nomenclatureCartT.t('total')}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="right">
                      {cart?.total?.volume}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="right">
                      {cart?.total?.weight}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align="center">
                      {cart?.total?.quantity}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align="right">
                      {cart?.total?.price}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}
            />
          </React.Fragment>
        </Paper>

        {cart?.uid && <OrderForm cartUID={cart.uid} />}
      </Layout.Content>
    </Layout>
  );
};
