import { Namespace, TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { IFormValues } from '../../interfaces';

export const ValidationSchema = (t: TFunction<Namespace<'validation'>>): Yup.SchemaOf<IFormValues> =>
  Yup.object().shape({
    recipient_uid: Yup.string().required(t('required')),
    contact_uid: Yup.string().required(t('required')),
    delivery_date: Yup.string().required(t('required')).nullable(),
    comment: Yup.string().required(t('required')),
    recipient_address: Yup.string(),
    phone: Yup.string(),
  });
