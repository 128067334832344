import React, { createContext, ReactNode, useContext, useState } from 'react';

import { OrderItemSchema, RetrieveOrderSchema } from '@app/models/orders';

interface IContext {
  onAddItem: (item: OrderItemSchema) => void;
  onDeleteItems: (uids: string[]) => void;
  onCountChange: (count: number, uid: string) => void;
  setData: (item: RetrieveOrderSchema | undefined) => void;
  data: RetrieveOrderSchema | undefined;
}

const OrderItemsContext = createContext<IContext>(null!);

function OrderItemsProvider({ children }: { children: ReactNode }) {
  const [data, setData] = useState<RetrieveOrderSchema | undefined>(undefined);

  const handleAddItem = (newItem: OrderItemSchema) => {
    setData((prevState) => ({ ...prevState, items: [...(prevState?.items || []), newItem] } as any));
  };

  const handleDeleteItem = (uids: string[]) => {
    setData(
      (prevState) =>
        ({ ...prevState, items: [...(prevState?.items?.filter((item) => !uids.includes(item.uid)) || [])] } as any)
    );
  };

  const handleCountChange = (count: number, uid: string) => {
    if (count === 0) {
      return handleDeleteItem([uid]);
    }

    setData(
      (prevState) =>
        ({
          ...data,
          items: [...(prevState?.items?.map((item) => (item.uid === uid ? { ...item, quantity: count } : item)) || [])],
        } as any)
    );
  };

  const value = {
    data,
    setData,
    onAddItem: handleAddItem,
    onDeleteItems: handleDeleteItem,
    onCountChange: handleCountChange,
  };
  return <OrderItemsContext.Provider value={value}>{children}</OrderItemsContext.Provider>;
}

const useOrderItems = () => {
  const context = useContext(OrderItemsContext);

  if (context === undefined) {
    throw new Error('useProviderState must be used within a OrderItemsProvider');
  }

  return context;
};

export { OrderItemsContext, OrderItemsProvider, useOrderItems };
