import React from 'react';

import { DeleteTwoTone } from '@ant-design/icons';
import { Button, Row, Space, Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { OrderItemSchema, OrderTotal } from '@app/models/orders';

import { useOrderItems } from '../../../../providers';
import { AddProductModal } from '../AddProductModal';

import { columns } from './data/constants';

interface IProps {
  orderTotal?: OrderTotal;
}

export const ItemsTable: React.FC<IProps> = (props) => {
  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
  const orderT = useTranslation(NSKeys.order);
  const commonT = useTranslation(NSKeys.common);
  const { data, onDeleteItems, onCountChange, onAddItem } = useOrderItems();

  const handleDeleteClick = async () => {
    onDeleteItems(selectedRows);
    setSelectedRows([]);
  };

  const handleInputChange = async (value: number, cartItem: OrderItemSchema) => {
    onCountChange(value, cartItem.uid);
  };

  return (
    <React.Fragment>
      <Row justify="space-between" align="middle" style={{ height: '64px' }}>
        <Typography.Title level={3} style={{ marginBottom: 20 }}>
          {orderT.t('itemsTable.titleEmpty')}
        </Typography.Title>
        <Space>
          <AddProductModal
            onSelectItem={onAddItem}
            cartProducts={data?.items?.map((el) => el.product.uid)}
            catalogUid={data?.catalog.uid || ''}
          />
          {!!selectedRows.length && (
            <Button type="link" icon={<DeleteTwoTone />} onClick={handleDeleteClick}>
              {commonT.t('removeSelected')}
            </Button>
          )}
        </Space>
      </Row>
      <Table<OrderItemSchema>
        className="tableClassname"
        columns={columns(orderT.t, handleInputChange)}
        dataSource={data?.items || undefined}
        pagination={false}
        rowKey="uid"
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedRows,
          onChange: (selectedRowKeys) => {
            setSelectedRows(selectedRowKeys as any);
          },
        }}
      />
    </React.Fragment>
  );
};
