import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { ListRolesSchema, ScopeConfig } from '@app/models/users';
import Api from '@app/services/api';

import { useSpace } from '../../SpaceSettingsProvider';

import { getPOSTRequestDataFromFormValues, getPUTRequestDataFromFormValues } from './data';
import { IFormValues } from './interfaces';

interface RolesContextType {
  roles?: ListRolesSchema[];
  config?: ScopeConfig[];
  loading: boolean;
  alertOpen: boolean;
  setAlertOpen: (value: boolean) => void;
  isFormFieldsTouched: boolean;
  setIsFormFieldsTouched: (value: boolean) => void;
  newRole?: ListRolesSchema;
  onAddRole: () => void;
  selectedRole?: ListRolesSchema;
  onRoleSelect: (role: ListRolesSchema) => void;
  nextRoleId?: number;
  setNextRoleId: (id?: number) => void;
  putRole: (formValues: IFormValues) => Promise<void>;
  postRole: (formValues: IFormValues) => Promise<void>;
  fetchData: () => void;
}

const RolesContext = createContext<RolesContextType>(null!);

const TEMPLATE_ROLE: ListRolesSchema = {
  name: '',
  pk: new Date().getDate(),
  system: false,
  $permissions: {
    update: true,
    delete: true,
  },
  permissions: [],
};

function RolesProvider({ children }: { children: ReactNode }) {
  const [roles, setRoles] = React.useState<ListRolesSchema[]>([]);
  const [config, setConfig] = React.useState<ScopeConfig[]>();
  const [newRole, setNewRole] = React.useState<ListRolesSchema | undefined>(undefined);
  const [selectedRole, setSelectedRole] = React.useState<ListRolesSchema | undefined>(undefined);
  const [loading, setLoading] = React.useState(true);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [isFormFieldsTouched, setIsFormFieldsTouched] = React.useState(false);
  // Сохраняем выбранное значение для модалки (если согласиться с предупреждением то выбираем nextTagId, иначе остаемся на текущем, а nextTagId обнуляем)
  const [nextRoleId, setNextRoleId] = useState<number | undefined>(undefined);
  const commonT = useTranslation(NSKeys.common);
  const { setEditing, setDisabledEdit, isEditing } = useSpace();

  const fetchData = async (keepSelected = true): Promise<void> => {
    try {
      setLoading(true);
      const resp = await Api.users.getRoles();
      const config = await Api.users.getRoleConfig();

      // setRoles(resp.items);
      setRoles(resp.items);
      setConfig(config?.scopes);
      if (keepSelected) {
        if (resp.items.length && resp.items[0]) {
          setSelectedRole(resp.items[0]);
        }
      }
    } catch {
      message.error(commonT.t('dataNotFound'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!isEditing && selectedRole?.name === TEMPLATE_ROLE.name) {
      setSelectedRole(roles[0]);
      setNewRole(undefined);
    }
  }, [isEditing]);

  useEffect(() => {
    if (selectedRole) {
      setDisabledEdit(selectedRole?.system || false);
    }
    if (selectedRole?.name !== TEMPLATE_ROLE.name) {
      setEditing(false);
      setNewRole(undefined);
    }
  }, [selectedRole]);

  const putRole = async (formValues: IFormValues) => {
    if (selectedRole) {
      const res = await Api.users.putRole(selectedRole.pk, getPUTRequestDataFromFormValues(formValues));
      setSelectedRole(res);
      setEditing(false);
      await fetchData(false);
    } else {
      throw new Error('not found activeRoles');
    }
  };

  const postRole = async (formValues: IFormValues) => {
    const res = await Api.users.postRole(getPOSTRequestDataFromFormValues(formValues));
    setEditing(false);
    setSelectedRole(res);
    await fetchData(false);
  };

  const handleAddNewRole = () => {
    setIsFormFieldsTouched(true);
    setEditing(true);
    setSelectedRole(TEMPLATE_ROLE);
    setNewRole(TEMPLATE_ROLE);
  };

  const onRoleSelect = (role: ListRolesSchema) => {
    setSelectedRole(role);
  };

  const value = {
    roles,
    config,
    loading,
    selectedRole,
    alertOpen,
    setAlertOpen,
    onRoleSelect,
    newRole,
    onAddRole: handleAddNewRole,
    putRole,
    postRole,
    isFormFieldsTouched,
    setIsFormFieldsTouched,
    nextRoleId,
    setNextRoleId,
    fetchData,
  };

  return <RolesContext.Provider value={value}>{children}</RolesContext.Provider>;
}

const useRoles = () => {
  const context = useContext(RolesContext);

  if (context === undefined) {
    throw new Error('useRolesState must be used within a RolesProvider');
  }

  return context;
};

export { RolesContext, RolesProvider, useRoles };
