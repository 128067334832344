import React from 'react';

import { Button, DatePicker, Form, Input, message, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { ContactsSelect, RecipientsSelect } from '@components/AntFields';
import { Paper } from '@components/Paper';

import { useCreateOrder } from '../../../../../../hooks';

import { IFormValues } from './interfaces';
import { ValidationSchema } from './validataion';

interface IProps {
  cartUID: string;
}

const MINIMUM_DATE = dayjs();

export const OrderForm: React.FC<IProps> = (props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm<IFormValues>();
  const nomenclatureCartT = useTranslation(NSKeys.nomenclatureCart);
  const validationT = useTranslation(NSKeys.validation);
  const commonT = useTranslation(NSKeys.common);
  const recipientUid = Form.useWatch('recipient_uid', form);

  const { mutateAsync, isLoading, status, data } = useCreateOrder();

  React.useEffect(() => {
    if (status === 'success' && data) {
      message.success(nomenclatureCartT.t('orderForm.successMessage'));
      navigate(`/dashboard/orders/${data?.uid}`);
    }
    if (status === 'error') {
      message.error(commonT.t('error400'));
    }
  }, [status, data]);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  const handleSubmit = async (values: IFormValues): Promise<void> => {
    await mutateAsync({
      data: {
        delivery_info: {
          recipient_uid: values.recipient_uid,
          contact_uid: values.contact_uid,
          comment: values.comment || '',
          delivery_date: dayjs(values.delivery_date).format('YYYY-MM-DD'),
        },
      },
      cart_uid: props.cartUID,
    });
  };

  return (
    <Paper padding="24px" style={{ margin: 24 }}>
      <Typography.Title level={5} style={{ margin: '0 0 24px 0' }}>
        {nomenclatureCartT.t('orderTitle')}
      </Typography.Title>

      <Form<IFormValues>
        form={form}
        initialValues={{}}
        name="order_form"
        id="orderForm"
        onFinish={handleSubmit}
        autoComplete="off"
        labelCol={{ sm: 8 }}
        wrapperCol={{ sm: 8 }}>
        <Form.Item<IFormValues>
          name="delivery_date"
          required
          label={nomenclatureCartT.t('orderForm.deliveryDate')}
          rules={[yupSync]}>
          <DatePicker minDate={MINIMUM_DATE} disabledDate={(d) => !d || d.isBefore(MINIMUM_DATE)} />
        </Form.Item>
        <Form.Item<IFormValues>
          name="recipient_uid"
          required
          label={nomenclatureCartT.t('orderForm.companyName')}
          rules={[yupSync]}>
          <RecipientsSelect
            creatable
            showSearch
            placeholder=""
            onSelect={(value, opt) => {
              form.setFieldValue('recipient_address', opt.data?.address?.as_string);
            }}
            onAddRecipient={(data) => {
              form.setFieldValue('recipient_uid', data?.uid);
              form.setFieldValue('recipient_address', data?.address?.as_string);
            }}
          />
        </Form.Item>
        <Form.Item<IFormValues>
          name="recipient_address"
          label={nomenclatureCartT.t('orderForm.companyAddress')}
          rules={[yupSync]}>
          <Input disabled />
        </Form.Item>
        <Form.Item<IFormValues>
          name="contact_uid"
          required
          label={nomenclatureCartT.t('orderForm.contactName')}
          rules={[yupSync]}>
          <ContactsSelect
            creatable
            recipientUid={recipientUid}
            onAddContact={(data) => {
              form.setFieldValue('contact_uid', data?.uid);
              form.setFieldValue('phone', data?.phone);
            }}
            showSearch
            placeholder=""
            onSelect={(value, opt) => {
              form.setFieldValue('phone', opt.data?.phone);
            }}
          />
        </Form.Item>
        <Form.Item<IFormValues> name="phone" label={nomenclatureCartT.t('orderForm.phone')} rules={[yupSync]}>
          <Input disabled />
        </Form.Item>
        <Form.Item<IFormValues>
          name="comment"
          label={nomenclatureCartT.t('orderForm.comment')}
          style={{ minWidth: '100%' }}
          rules={[yupSync]}>
          <Input.TextArea
            maxLength={144}
            rows={2}
            showCount
            placeholder={nomenclatureCartT.t('orderForm.commentPlaceholder')}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={isLoading}>
            {nomenclatureCartT.t('orderForm.submitButton')}
          </Button>
        </Form.Item>
      </Form>
    </Paper>
  );
};
