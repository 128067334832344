import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Form, Radio, Row, Select } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { OrderCategory, OrderStatus } from '@app/models/orders';
import { useLocalDispatch, useLocalSelector } from '@app/services/hooks/useRedux';
import { reset, setFilters } from '@app/store/ordersReducer';
import { CatalogsSelect, UsersSelect } from '@components/AntFields';
import { TableContolsContainer } from '@components/TableControlsContainer';

import { FilterEditMenu } from './components/FilterEditMenu';

import { Filters } from './interfaces';
import { Container } from './styles';

interface IProps {
  isLoading?: boolean;
}

export const SearchControls: React.FC<IProps> = (props) => {
  const dispatch = useLocalDispatch();
  const ordersT = useTranslation(NSKeys.orders);
  const commonT = useTranslation(NSKeys.common);
  const FILTERS = [
    // { value: Filters.Created, label: ordersT.t('filters.created_at') },
    { value: Filters.Catalog, label: ordersT.t('filters.catalog_uid') },
    { value: Filters.Author, label: ordersT.t('filters.author_id') },
    { value: Filters.Status, label: ordersT.t('filters.status') },
  ];
  const CATEGORY_OPTIONS = [
    { label: ordersT.t('filters.category.all'), value: OrderCategory.All },
    { label: ordersT.t('filters.category.mine'), value: OrderCategory.Mine },
    { label: ordersT.t('filters.category.to_approve'), value: OrderCategory.ToApprove },
    { label: ordersT.t('filters.category.approved'), value: OrderCategory.ApprovedByMe },
  ];
  const STATUS_OPTIONS = [
    { value: OrderStatus.NeedsWork, label: ordersT.t('statusNeedsWork') },
    { value: OrderStatus.Approved, label: ordersT.t('statusАpproved') },
    { value: OrderStatus.Cancelled, label: ordersT.t('statusCancelled') },
    { value: OrderStatus.NeedsApprove, label: ordersT.t('statusNeedsApprove') },
  ];
  const category = useLocalSelector((state) => state.orders.category);
  const catalog_uid = useLocalSelector((state) => state.orders.catalog_uid);
  const author_id = useLocalSelector((state) => state.orders.author_id);
  const status = useLocalSelector((state) => state.orders.status);
  const created_at = useLocalSelector((state) => state.orders.created_at);
  const [selectedFilters, setSelectedFilters] = React.useState<Filters[]>([]);

  const handleReset = () => {
    setSelectedFilters([]);
    dispatch(reset());
  };

  return (
    <TableContolsContainer showBadge={category !== OrderCategory.All || !!catalog_uid || !!author_id || !!status}>
      <React.Fragment>
        <Flex gap={16} style={{ marginBottom: 16 }} justify="space-between" wrap={'wrap'}>
          <Radio.Group
            options={CATEGORY_OPTIONS}
            onChange={(e) => dispatch(setFilters({ category: e.target.value }))}
            value={category}
            optionType="button"
          />
          <FilterEditMenu filters={FILTERS} selectedFilters={selectedFilters} onChange={setSelectedFilters} />
        </Flex>
        {!!selectedFilters.length && (
          <Flex gap={16} wrap={'wrap'}>
            {selectedFilters.map((filter, index) => (
              <Col style={{ width: 250 }} key={`filter-${index}`}>
                <Form.Item>
                  {filter === Filters.Author && (
                    <UsersSelect
                      placeholder={ordersT.t('filters.author_id')}
                      value={author_id}
                      onChange={(e) => dispatch(setFilters({ author_id: e }))}
                    />
                  )}
                  {filter === Filters.Status && (
                    <Select
                      value={status}
                      placeholder={ordersT.t('filters.status')}
                      onChange={(value) => dispatch(setFilters({ status: value }))}
                      options={STATUS_OPTIONS}
                    />
                  )}
                  {filter === Filters.Catalog && (
                    <CatalogsSelect
                      value={catalog_uid}
                      placeholder={ordersT.t('filters.catalog_uid')}
                      onChange={(value) => dispatch(setFilters({ catalog_uid: value }))}
                    />
                  )}
                </Form.Item>
              </Col>
            ))}

            <Form.Item style={{ marginBottom: 10 }}>
              <Button type="link" icon={<DeleteOutlined />} onClick={handleReset}>
                {commonT.t('buttonReset')}
              </Button>
            </Form.Item>
          </Flex>
        )}
      </React.Fragment>
    </TableContolsContainer>
  );
};
