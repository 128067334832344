/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Body_uploadCatalogCover */
export interface BodyUploadCatalogCover {
  /**
   * Media
   * Image file
   * @format binary
   */
  media: File;
}

/** Body_uploadProductImage */
export interface BodyUploadProductImage {
  /**
   * Media
   * Image file
   * @format binary
   */
  media: File;
}

/** CartItemProductSchema */
export interface CartItemProductSchema {
  /** Uid */
  uid: string;
  /** Title */
  title: string;
  /** Code */
  code: string | null;
  /** Description */
  description: string | null;
}

/** CartItemSchema */
export interface CartItemSchema {
  /** Uid */
  uid: string;
  product: CartItemProductSchema;
  /** Quantity */
  quantity: number;
  /** Volume */
  volume: string;
  /** Price */
  price: string;
  /** Weight */
  weight: string;
}

/** CartSchema */
export interface CartSchema {
  /** Uid */
  uid: string | null;
  /** Items */
  items: CartItemSchema[] | null;
  /** Items Total */
  items_total: number;
  total: CartTotalSchema | null;
}

/** CartTotalSchema */
export interface CartTotalSchema {
  /** Quantity */
  quantity: number;
  /** Volume */
  volume: string;
  /** Price */
  price: string;
  /** Weight */
  weight: string;
}

/** CatalogCoverSchema */
export interface CatalogCoverSchema {
  /** Uid */
  uid: string;
  variants: CatalogCoverVariantsSchema;
}

/** CatalogCoverVariantsSchema */
export interface CatalogCoverVariantsSchema {
  /** size: 64x64 */
  thumbnailSmall?: ImageVariant;
  /** size: 260x150 */
  thumbnail?: ImageVariant;
}

/** CatalogNotUniqueError */
export interface CatalogNotUniqueError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'catalog_name_not_unique';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** CatalogSchema */
export interface CatalogSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  cover: CatalogCoverSchema | null;
  warehouse: CatalogWarehouseSchema | null;
  /** CatalogPermissions */
  $permissions: {
    /** Delete */
    delete?: boolean;
    /** Manage Products */
    manage_products?: boolean;
    /** Update */
    update?: boolean;
    /** View */
    view?: boolean;
    /** View Cart */
    view_cart?: boolean;
    /** View Products */
    view_products?: boolean;
    /** View Settings */
    view_settings?: boolean;
  };
}

/** CatalogSettingsSchema */
export interface CatalogSettingsSchema {
  visibility_rule: CatalogVisibilityRule;
  /** Visible To Users */
  visible_to_users: SimpleUserSchema[] | null;
  /** Visible To User Groups */
  visible_to_user_groups: SimpleUserGroupSchema[] | null;
  order_approval_chain: SimpleOrderApprovalChainSchema | null;
  order_approval_rule: OrderApprovalRule;
}

/** CatalogVisibilityRule */
export enum CatalogVisibilityRule {
  Hidden = 'hidden',
  VisibleToGroups = 'visible_to_groups',
  VisibleToUsers = 'visible_to_users',
  Public = 'public',
}

/** CatalogWarehouseSchema */
export interface CatalogWarehouseSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
}

/** ContextSchema */
export interface ContextSchema {
  scope: ContextScopeSchema;
}

/** ContextScopeSchema */
export interface ContextScopeSchema {
  /** ScopePermissions */
  $permissions: {
    /** Create Products */
    create_products?: boolean;
    /** Create Catalogs */
    create_catalogs?: boolean;
    /** Upload Catalog Covers */
    upload_catalog_covers?: boolean;
    /** Upload Product Images */
    upload_product_images?: boolean;
    /** View Catalogs */
    view_catalogs?: boolean;
    /** View Products */
    view_products?: boolean;
    /** View Dictionaries */
    view_dictionaries?: boolean;
  };
}

/** CreateCatalogSchema */
export interface CreateCatalogSchema {
  /** Name */
  name: string;
  /** Cover Uid */
  cover_uid?: string | null;
  /** Warehouse Uid */
  warehouse_uid?: string | null;
  settings: CreateCatalogSettingsSchema;
}

/** CreateCatalogSettingsSchema */
export interface CreateCatalogSettingsSchema {
  visibility_rule: CatalogVisibilityRule;
  /** Visible To User Group Uids */
  visible_to_user_group_uids?: string[] | null;
  /** Visible To User Ids */
  visible_to_user_ids?: number[] | null;
  order_approval_rule: OrderApprovalRule;
  /** Order Approval Chain Uid */
  order_approval_chain_uid?: string | null;
}

/** CreateDictionaryItemDTO */
export interface CreateDictionaryItemDTO {
  /** Title */
  title: string;
}

/** CreateProductSchema */
export interface CreateProductSchema {
  /** Title */
  title: string;
  /** Code */
  code?: string | null;
  /** Description */
  description?: string | null;
  /** Weight */
  weight?: number | string | null;
  /** Length */
  length?: number | string | null;
  /** Height */
  height?: number | string | null;
  /** Width */
  width?: number | string | null;
  /** Price */
  price?: number | string | null;
  /** Package Amount */
  package_amount?: number | null;
  /**
   * Is Active
   * @default true
   */
  is_active?: boolean;
  /**
   * Tags Ids
   * @default []
   */
  tags_ids?: number[];
  /** Category Id */
  category_id?: number | null;
  /** Brand Id */
  brand_id?: number | null;
  /** Image Uids */
  image_uids?: string[] | null;
}

/** DeleteCartItemResponseSchema */
export interface DeleteCartItemResponseSchema {
  /** Cart Items Total */
  cart_items_total: number;
  cart_total: CartTotalSchema;
}

/** DictionaryItemNotUniqueError */
export interface DictionaryItemNotUniqueError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'dictionary_title_not_unique';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** DictionaryItemSchema */
export interface DictionaryItemSchema {
  /** Id */
  id: number;
  /** Title */
  title: string;
  /** DictionaryItemPermissions */
  $permissions: {
    /** Delete */
    delete?: boolean;
  };
}

/** DictionarySchema */
export interface DictionarySchema {
  /** Id */
  id: number;
  /** Slug */
  slug: string;
  /** Title */
  title: string;
  /** DictionaryPermissions */
  $permissions: {
    /** View */
    view?: boolean;
    /** Create Item */
    create_item?: boolean;
  };
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** ImageVariant */
export interface ImageVariant {
  /** Url */
  url?: string;
}

/** OrderApprovalRule */
export enum OrderApprovalRule {
  NotNeeded = 'not_needed',
  OrderApprovalChain = 'order_approval_chain',
}

/** PhotoImageVariantsSchema */
export interface PhotoImageVariantsSchema {
  /** size: 64x64 */
  thumbnailSmall?: ImageVariant;
  /** size: 260x150 */
  thumbnail?: ImageVariant;
  /** size: fit-in/540x770 */
  normal?: ImageVariant;
  /** size: fit-in/1960x1280 */
  fullscreen?: ImageVariant;
}

/** ProductCartItemSchema */
export interface ProductCartItemSchema {
  /** Uid */
  uid: string;
  /** Quantity */
  quantity: number;
}

/** ProductImageSchema */
export interface ProductImageSchema {
  /** Uid */
  uid: string;
  variants: PhotoImageVariantsSchema;
}

/** ProductNotInCatalogError */
export interface ProductNotInCatalogError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'product_not_in_catalog';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** ProductUidDTO */
export interface ProductUidDTO {
  /** Product Uid */
  product_uid: string;
}

/** RecordsListGenericSchema[CatalogSchema] */
export interface RecordsListGenericSchemaCatalogSchema {
  /** Items */
  items: CatalogSchema[];
  /**
   * Total
   * @default 0
   */
  total?: number;
}

/** RecordsListGenericSchema[ViewProductInCatalogSchema] */
export interface RecordsListGenericSchemaViewProductInCatalogSchema {
  /** Items */
  items: ViewProductInCatalogSchema[];
  /**
   * Total
   * @default 0
   */
  total?: number;
}

/** RecordsListGenericSchema[ViewProductSchema] */
export interface RecordsListGenericSchemaViewProductSchema {
  /** Items */
  items: ViewProductSchema[];
  /**
   * Total
   * @default 0
   */
  total?: number;
}

/** RemoveCartItemsDTO */
export interface RemoveCartItemsDTO {
  /** Item Uids */
  item_uids: string[];
}

/** SimpleDictionaryItemSchema */
export interface SimpleDictionaryItemSchema {
  /** Id */
  id: number;
  /** Title */
  title: string;
}

/** SimpleOrderApprovalChainSchema */
export interface SimpleOrderApprovalChainSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
}

/** SimpleUserGroupSchema */
export interface SimpleUserGroupSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
}

/** SimpleUserSchema */
export interface SimpleUserSchema {
  /** Id */
  id: number;
  /** Name */
  name: string;
}

/** SystemDictionarySlugs */
export enum SystemDictionarySlugs {
  Tags = 'tags',
  Brands = 'brands',
  Categories = 'categories',
}

/** UpdateCartItemDTO */
export interface UpdateCartItemDTO {
  /** Product Uid */
  product_uid: string;
  /**
   * Quantity
   * @min 1
   * @default 1
   */
  quantity?: number;
  /**
   * Updating
   * Обновлять существующую позицию
   * @default true
   */
  updating?: boolean;
}

/** UpdateCartItemResponseSchema */
export interface UpdateCartItemResponseSchema {
  cart_item: CartItemSchema;
  /** Cart Items Total */
  cart_items_total: number;
  cart_total: CartTotalSchema;
}

/** UpdateCatalogSchema */
export interface UpdateCatalogSchema {
  /** Name */
  name: string;
  /** Cover Uid */
  cover_uid?: string | null;
  /** Warehouse Uid */
  warehouse_uid?: string | null;
  settings: CreateCatalogSettingsSchema;
}

/** UpdateProductSchema */
export interface UpdateProductSchema {
  /** Title */
  title: string;
  /** Code */
  code?: string | null;
  /** Description */
  description?: string | null;
  /** Weight */
  weight?: number | string | null;
  /** Length */
  length?: number | string | null;
  /** Height */
  height?: number | string | null;
  /** Width */
  width?: number | string | null;
  /** Price */
  price?: number | string | null;
  /** Package Amount */
  package_amount?: number | null;
  /**
   * Is Active
   * @default true
   */
  is_active?: boolean;
  /**
   * Tags Ids
   * @default []
   */
  tags_ids?: number[];
  /** Category Id */
  category_id?: number | null;
  /** Brand Id */
  brand_id?: number | null;
  /** Image Uids */
  image_uids?: string[] | null;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** ViewProductInCatalogSchema */
export interface ViewProductInCatalogSchema {
  /** Title */
  title: string;
  /** Code */
  code?: string | null;
  /** Description */
  description?: string | null;
  /** Weight */
  weight?: string | null;
  /** Length */
  length?: string | null;
  /** Height */
  height?: string | null;
  /** Width */
  width?: string | null;
  /** Price */
  price?: string | null;
  /** Package Amount */
  package_amount?: number | null;
  /**
   * Is Active
   * @default true
   */
  is_active?: boolean;
  /** Uid */
  uid: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /** Images */
  images: ProductImageSchema[];
  /** Tags */
  tags: SimpleDictionaryItemSchema[];
  brand: SimpleDictionaryItemSchema | null;
  category: SimpleDictionaryItemSchema | null;
  /** ProductPermissions */
  $permissions: {
    /** Add To Catalog */
    add_to_catalog?: boolean;
    /** Update */
    update?: boolean;
    /** View */
    view?: boolean;
  };
  cart_item: ProductCartItemSchema | null;
}

/** ViewProductSchema */
export interface ViewProductSchema {
  /** Title */
  title: string;
  /** Code */
  code?: string | null;
  /** Description */
  description?: string | null;
  /** Weight */
  weight?: string | null;
  /** Length */
  length?: string | null;
  /** Height */
  height?: string | null;
  /** Width */
  width?: string | null;
  /** Price */
  price?: string | null;
  /** Package Amount */
  package_amount?: number | null;
  /**
   * Is Active
   * @default true
   */
  is_active?: boolean;
  /** Uid */
  uid: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /** Images */
  images: ProductImageSchema[];
  /** Tags */
  tags: SimpleDictionaryItemSchema[];
  brand: SimpleDictionaryItemSchema | null;
  category: SimpleDictionaryItemSchema | null;
  /** ProductPermissions */
  $permissions: {
    /** Add To Catalog */
    add_to_catalog?: boolean;
    /** Update */
    update?: boolean;
    /** View */
    view?: boolean;
  };
}

/** _Source */
export interface Source {
  /** Pointer */
  pointer?: string | null;
  /** Header */
  header?: string | null;
  /** Parameter */
  parameter?: string | null;
}
