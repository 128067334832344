import { useQuery } from '@tanstack/react-query';

import { IOrdersParams } from '@app/models/interfaces';
import Api from '@app/services/api';

export const useGetOrdersQuery = ({
  limit,
  offset,
  category,
  status,
  author_id,
  created_at,
  catalog_uid,
}: IOrdersParams) => {
  return useQuery({
    queryKey: ['getOrders', limit, offset, category, status, author_id, created_at, catalog_uid],
    queryFn: () => Api.orders.getOrders({ limit, offset, category, status, author_id, created_at, catalog_uid }),
  });
};
