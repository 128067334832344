import React from 'react';

import { ColumnsType } from 'antd/es/table';

import { RetrieveOrderSchema } from '@app/models/orders';
import { formatDateAndTimeShort } from '@app/services/date';

import { Status } from '../../../components/Status';

export const BASE_COLUMNS_WIP: ColumnsType<RetrieveOrderSchema> = [
  {
    title: 'tableColumns.num',
    dataIndex: 'delivery_info.company.num',
    key: 'num',
    width: 120,
    ellipsis: true,
    render: (_, data) => data.num,
  },
  {
    title: 'tableColumns.created_at',
    dataIndex: 'created_at',
    key: 'created_at',
    ellipsis: true,
    width: 200,
    render: (data) => formatDateAndTimeShort(data),
  },
  {
    title: 'tableColumns.company_name',
    dataIndex: 'delivery_info.company.name',
    key: 'companyName',
    // sorter: true,
    ellipsis: true,
    width: 200,
    render: (_, { delivery_info }) => delivery_info.recipient?.name,
  },
  {
    title: 'tableColumns.catalog',
    dataIndex: 'delivery_info.catalog.name',
    key: 'catalogName',
    // sorter: true,
    ellipsis: true,
    width: 200,
    render: (_, { catalog }) => catalog.name,
  },
  {
    title: 'tableColumns.address',
    dataIndex: 'delivery_info.company.address',
    key: 'companyAddress',
    // sorter: true,
    ellipsis: true,
    width: 200,
    render: (_, { delivery_info }) => delivery_info.recipient?.address?.as_string,
  },
  {
    title: 'tableColumns.contact_name',
    dataIndex: 'delivery_info.company.contact_name',
    key: 'companyContactName',
    // sorter: true,
    ellipsis: true,
    width: 200,
    render: (_, { delivery_info }) => delivery_info.contact?.name,
  },
  {
    title: 'tableColumns.phone',
    dataIndex: 'delivery_info.company.contact_phone',
    key: 'companyContactPhone',
    ellipsis: true,
    width: 200,
    render: (_, { delivery_info }) => delivery_info.contact?.phone,
  },
  {
    title: 'tableColumns.author',
    dataIndex: 'author.name',
    key: 'authorName',
    ellipsis: true,
    width: 200,
    render: (_, order) => order.author.name,
  },
  {
    title: 'tableColumns.status',
    key: 'status',
    align: 'center',
    dataIndex: 'status',
    width: 200,
    render: (_, { status }) => <Status status={status} />,
  },
];
