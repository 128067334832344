import React from 'react';

import { CheckOutlined, CloseOutlined, EditOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button, Layout, List, Row, Space, Steps, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { OrderStatus } from '@app/models/orders';
import { formatDate, formatDateAndTime } from '@app/services/date';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { HeaderTitle } from '@components/HeaderTitle';

import { ListItem } from '@components/ListItem';
import { Paper } from '@components/Paper';

import { Status } from '../../components/Status';

import { useApproveQuery, useCancelQuery, useGetOrderQuery, useMarkAsNeedWorkQuery } from '../../hooks';

import { HistoryTable } from './components/HistoryTable';
import { ItemsTable } from './components/ItemsTable';
import { StatusChangeModal } from './components/StatusChangeModal';

export const Single: React.FC = () => {
  const navigate = useNavigate();
  const orderT = useTranslation(NSKeys.order);
  const commonT = useTranslation(NSKeys.common);
  const params = useParams<{ id: string }>();

  // const showCancel = useMemo(() => data?.status.name !== 'CANCELLED' && data?.status.name !== 'CONFIRMED', [data]);
  const { data, isLoading } = useGetOrderQuery(params?.id || '');
  const {
    mutate: markAsNeedWork,
    isLoading: markIsNeedWorkLoading,
    status: markIsNeedStatus,
  } = useMarkAsNeedWorkQuery();
  const { mutate: approve, isLoading: approveLoading, status: approveStatus } = useApproveQuery();
  const { mutate: cancel, isLoading: cancelLoading, status: cancelStatus } = useCancelQuery();

  const goBack = () => navigate('/dashboard/orders');

  return (
    <Layout>
      <Layout.Header style={{ borderBottom: 'none' }}>
        <Breadcrumbs apiTitles={[{ id: ':ordersId', value: `${orderT.t('breadCrumbPrefix')} ${data?.num}` }]} />
        <Row justify="space-between" align="middle" style={{ minHeight: 64 }}>
          <HeaderTitle loading={isLoading} title={`${data?.num || params.id}`} onClick={goBack}>
            <Status status={data?.status} />
          </HeaderTitle>
          {data?.version && [OrderStatus.NeedsWork, OrderStatus.NeedsApprove].some((el) => el === data?.status) && (
            <Space wrap>
              {data?.$operations?.approve && data?.$permissions?.approve && (
                <Button
                  icon={<CheckOutlined />}
                  onClick={() =>
                    approve({
                      order_uid: params.id || '',
                      data: {
                        version: data.version,
                        comment: '',
                      },
                    })
                  }
                  loading={approveLoading}
                  size="middle"
                  type="primary">
                  {commonT.t('buttonApprove')}
                </Button>
              )}
              {data?.$operations?.cancel && data?.$permissions?.cancel && (
                <StatusChangeModal
                  isLoading={cancelLoading}
                  onSubmit={(data) => cancel({ order_uid: params.id || '', data })}
                  status={cancelStatus}
                  version={data.version}
                  buttonContent={commonT.t('buttonCancel')}
                  buttonIcon={<CloseOutlined />}
                />
              )}
              {data?.$operations?.mark_as_needs_work && data?.$permissions?.mark_as_needs_work && (
                <StatusChangeModal
                  isLoading={markIsNeedWorkLoading}
                  onSubmit={(data) => markAsNeedWork({ order_uid: params.id || '', data })}
                  status={markIsNeedStatus}
                  version={data.version}
                  buttonContent={orderT.t('buttonMarkAsNeedWork')}
                  buttonIcon={<RetweetOutlined />}
                />
              )}
              {data?.$operations?.update && data?.$permissions?.update && (
                <Button
                  icon={<EditOutlined />}
                  onClick={() => navigate(`/dashboard/orders/edit/${params?.id}`)}
                  size="middle">
                  {commonT.t('edit')}
                </Button>
              )}
            </Space>
          )}

          {/* {showCancel && (*/}
          {/*  <PermissionGate permissionId="ORDERS_CANCEL">*/}
          {/*    <Button*/}
          {/*      icon={<CloseOutlined style={{ color: token.colorPrimary }} />}*/}
          {/*      type="default"*/}
          {/*      size="middle"*/}
          {/*      onClick={CancelCard}>*/}
          {/*      {commonT.t('cancel')}*/}
          {/*    </Button>*/}
          {/*  </PermissionGate>*/}
          {/* )}*/}
        </Row>
      </Layout.Header>

      <Paper style={{ margin: '24px', padding: '24px 24px' }}>
        <Typography.Title level={3}>{orderT.t('blockInfo.title')}</Typography.Title>
        <List style={{ maxWidth: 600, margin: '20px 0' }} loading={isLoading}>
          <ListItem title={orderT.t('blockInfo.num')} description={data?.num} />
          <ListItem title={orderT.t('blockInfo.created_at')} description={formatDateAndTime(data?.created_at)} />
          <ListItem title={orderT.t('blockInfo.authorName')} description={data?.author.name} />
          <ListItem title={orderT.t('blockInfo.status')} description=" ">
            <Status status={data?.status} />
          </ListItem>
          <ListItem title={orderT.t('blockInfo.catalogName')} description={data?.catalog.name} />
          <ListItem
            title={orderT.t('blockInfo.orderApprovalChainName')}
            description={data?.order_approval_chain?.name}
          />
        </List>

        <Typography.Title level={3}>{orderT.t('blockDelivery.title')}</Typography.Title>
        <List style={{ maxWidth: 600, margin: '20px 0' }} loading={isLoading}>
          <ListItem
            title={orderT.t('blockDelivery.delivery_date')}
            description={formatDate(data?.delivery_info.delivery_date)}
          />
          <ListItem title={orderT.t('blockDelivery.name')} description={data?.delivery_info.recipient?.name} />
          <ListItem
            title={orderT.t('blockDelivery.address')}
            description={data?.delivery_info.recipient?.address?.as_string}
          />
          <ListItem title={orderT.t('blockDelivery.contact_name')} description={data?.delivery_info?.contact?.name} />
          <ListItem title={orderT.t('blockDelivery.contact_phone')} description={data?.delivery_info?.contact?.phone} />
          <ListItem title={orderT.t('blockDelivery.comment')} description={data?.delivery_info.comment} />
        </List>

        <Typography.Title level={3}>{orderT.t('blockSteps.title')}</Typography.Title>
        <Steps
          labelPlacement="vertical"
          size="small"
          style={{ margin: '20px 0' }}
          items={data?.approval_route?.steps.map((step, i) => ({
            title: orderT.t('blockSteps.steps', { step: i + 1 }),
            status: step?.is_current ? 'process' : step?.is_passed ? 'finish' : 'wait',
            description: step.user_group.name,
          }))}
        />

        <ItemsTable isLoading={isLoading} data={data} />
        {params.id && data?.$permissions?.view && <HistoryTable order_uid={params.id} />}
      </Paper>
    </Layout>
  );
};
