import React from 'react';

import { Select, SelectProps } from 'antd';

import { useGroupsQuery } from './hooks/useGroupsQuery';

interface IProps extends SelectProps {
  placeholder: string;
}

export const GroupsSelect: React.FC<IProps> = ({ placeholder, ...props }) => {
  const { data, hasNextPage, fetchNextPage } = useGroupsQuery({
    size: 10,
  });

  const dataSource = data?.pages?.map((data) => data.items || []).flat() || [];

  return (
    <Select
      mode="multiple"
      allowClear
      placeholder={placeholder}
      options={dataSource?.map((el) => ({
        label: `${el.name}`,
        value: el.uid,
      }))}
      dropdownStyle={{ position: 'fixed' }}
      onPopupScroll={(e) => {
        e.persist();
        const target = e.currentTarget;
        if (target?.scrollTop + target?.offsetHeight === target?.scrollHeight && hasNextPage) {
          fetchNextPage();
        }
      }}
      {...props}
    />
  );
};
