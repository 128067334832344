import React from 'react';

import { DatePicker, Form, FormInstance, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { ContactsSelect, PhoneInput, RecipientsSelect } from '@components/AntFields';

import { IFormValues } from '../../interfaces';

import { ValidationSchema } from './validations';

interface IProps {
  initialValues: IFormValues;
  onFinish: (formValues: IFormValues) => void;
  form: FormInstance;
}

export const OrderForm: React.FC<IProps> = (props) => {
  const orderT = useTranslation(NSKeys.order);
  const validationT = useTranslation(NSKeys.validation);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  return (
    <Form<IFormValues>
      form={props.form}
      name="updateOrder"
      id="updateOrder"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 8 }}
      initialValues={props.initialValues}
      onFinish={props.onFinish}>
      <Form.Item<IFormValues>
        name="delivery_date"
        required
        label={orderT.t('blockDelivery.delivery_date')}
        rules={[yupSync]}>
        <DatePicker format="YYYY-MM-DD" />
      </Form.Item>
      <Form.Item<IFormValues> name="recipient_uid" required label={orderT.t('blockDelivery.name')} rules={[yupSync]}>
        <RecipientsSelect
          showSearch
          placeholder=""
          // disableValues={recipients.map((el) => el.uid || '')}
          onSelect={(value, opt) => {
            props.form.setFieldValue('recipient_address', opt.data?.address?.as_string);
          }}
        />
      </Form.Item>
      <Form.Item<IFormValues> name="recipient_address" label={orderT.t('blockDelivery.address')} rules={[yupSync]}>
        <Input disabled />
      </Form.Item>
      <Form.Item<IFormValues>
        name="contact_uid"
        required
        label={orderT.t('blockDelivery.contact_name')}
        rules={[yupSync]}>
        <ContactsSelect
          showSearch
          placeholder="test"
          onSelect={(value, opt) => {
            props.form.setFieldValue('phone', opt.data?.phone);
          }}
        />
      </Form.Item>
      <Form.Item<IFormValues> name="phone" label={orderT.t('blockDelivery.contact_phone')} rules={[yupSync]}>
        <Input disabled />
      </Form.Item>
      <Form.Item<IFormValues>
        name="comment"
        label={orderT.t('blockDelivery.comment')}
        style={{ minWidth: '100%' }}
        rules={[yupSync]}>
        <Input.TextArea maxLength={144} rows={2} showCount />
      </Form.Item>
    </Form>
  );
};
