import React from 'react';

import { SettingOutlined } from '@ant-design/icons';
import { Badge, Button, Collapse } from 'antd';
import { useMediaQuery } from 'usehooks-ts';

interface IProps {
  showBadge?: boolean;
  children: JSX.Element;
}

const KEY_CODE = 'tableMenu';

export const TableContolsContainer: React.FC<IProps> = (props) => {
  const [key, setActiveCollapseKey] = React.useState('');
  const isMediumDevice = useMediaQuery('(max-width : 790px)');

  const toggleCollapse = () => setActiveCollapseKey(key === KEY_CODE ? '' : KEY_CODE);

  const renderContent = () => <>{props.children}</>;

  if (isMediumDevice) {
    return (
      <div
        style={{
          display: 'inline-flex',
          width: '100%',
          justifyContent: 'flex-end',
          flexDirection: 'column',
          alignItems: 'end',
        }}>
        <Badge dot={props.showBadge} status="warning">
          <Button
            type="primary"
            shape="circle"
            icon={<SettingOutlined />}
            size="large"
            onClick={() => toggleCollapse()}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          />
        </Badge>
        <Collapse collapsible="icon" ghost activeKey={key} expandIcon={() => null}>
          <Collapse.Panel header="" key={KEY_CODE}>
            {renderContent()}
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  }

  return renderContent();
};
