import React, { FC, useState } from 'react';

import { Layout, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import type { ResizeCallbackData } from 'react-resizable';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { NSKeys } from '@app/i18n';
import { TableParams } from '@app/models/interfaces';
import { RetrieveOrderSchema } from '@app/models/orders';
import { ORDERS_TABLE_COLUMNS } from '@app/services/appSettings';
import { useLocalSelector } from '@app/services/hooks/useRedux';
import { TableDnDResizable } from '@components/TableDnDResizable';
import { TableEditMenu } from '@components/TableEditMenu';

import { useGetOrdersQuery } from '../../hooks';

import { ExportButton } from './components/ExportButton';
import { SearchControls } from './components/SearchControls';
import { BASE_COLUMNS_WIP } from './data/constants';
import { Container } from './styles';

const INITIAL_TABLE_PARAMS = {
  pagination: {
    current: 1,
    pageSize: 10,
  },
};

export const List: FC = () => {
  const navigate = useNavigate();
  const commonT = useTranslation(NSKeys.common);
  const ordersT = useTranslation(NSKeys.orders);
  const [tableParams, setTableParams] = useState<TableParams>(INITIAL_TABLE_PARAMS);
  const [localStorageColumns, setLocalStorageColumns] = useLocalStorage(
    ORDERS_TABLE_COLUMNS,
    BASE_COLUMNS_WIP.map(({ render, ...rest }) => rest)
  );
  const handleTableHeaderChange = (checkedValues: any[]) => {
    setLocalStorageColumns(BASE_COLUMNS_WIP.filter((item) => checkedValues.includes(item.key as string)));
  };

  const category = useLocalSelector((state) => state.orders.category);
  const catalog_uid = useLocalSelector((state) => state.orders.catalog_uid);
  const author_id = useLocalSelector((state) => state.orders.author_id);
  const status = useLocalSelector((state) => state.orders.status);
  const created_at = useLocalSelector((state) => state.orders.created_at);

  const { data: tableData, isLoading } = useGetOrdersQuery({
    offset:
      ((tableParams.pagination?.current || INITIAL_TABLE_PARAMS.pagination.current) - 1) *
      (tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize),
    limit: tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize,
    category,
    catalog_uid,
    author_id,
    status,
    created_at,
  });

  const handleResize =
    (index: number) =>
    (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
      const newColumns = [...localStorageColumns];
      newColumns[index] = {
        ...newColumns[index],
        width: size.width > 20 ? size.width : 20,
      };
      setLocalStorageColumns(newColumns);
    };

  return (
    <>
      <Layout.Header>
        <Row justify="space-between" align="middle">
          <Typography.Title level={2} className="title" style={{ marginBottom: 0 }}>
            {ordersT.t('title')}
          </Typography.Title>
          <ExportButton />
        </Row>
        <SearchControls />
      </Layout.Header>

      <Container>
        {/* <TableRemoveButton hidden={!selectedRows.length} onClick={() => console.log()} /> */}
        <TableDnDResizable<RetrieveOrderSchema>
          localStorageName={ORDERS_TABLE_COLUMNS}
          columns={[
            ...localStorageColumns.map((el, index) => ({
              ...el,
              title: ordersT.t(el.title as any),
              onHeaderCell: (column: any) => ({
                width: column.width,
                onResize: handleResize(index) as React.ReactEventHandler<any>,
              }),
              render: BASE_COLUMNS_WIP.find((baseCol) => baseCol.key === el.key)?.render,
            })),
          ]}
          actionColumn={[
            {
              title: (
                <TableEditMenu
                  handleOnChange={handleTableHeaderChange}
                  defaultValue={localStorageColumns?.map((el) => el.key as string)}
                  options={BASE_COLUMNS_WIP?.map((el) => ({
                    value: el.key as string,
                    label: ordersT.t(el.title as any),
                  }))}
                />
              ),
              key: 'action',
              align: 'center',
              width: 40,
              render: (_, product) => null,
            },
          ]}
          key="uid"
          onColumnsChange={setLocalStorageColumns}
          rowClassName="cursorPointer"
          className="tableClassname"
          loading={isLoading}
          dataSource={tableData?.items}
          style={{ marginTop: 24 }}
          scroll={{ x: 'max-content' }}
          pagination={{
            ...tableParams.pagination,
            total: tableData?.total,
            showQuickJumper: true,
            showSizeChanger: true,
            position: ['bottomCenter'],
            showTotal: (total, range) => `${range[0]}-${range[1]} ${commonT.t('paginationOf')} ${total}`,
          }}
          onRow={(data) => {
            return {
              onClick: (e) => data?.$permissions?.view && navigate(`${data.uid}`),
            };
          }}
          onChange={(pagination, filters, sorter) => {
            setTableParams({
              pagination,
              filters,
              ...sorter,
            });
          }}
          // rowSelection={{
          //   type: 'checkbox',
          //   selectedRowKeys: selectedRows,
          //   renderCell: (value, record, index, originNode) => (
          //     <div onClick={(e) => e.stopPropagation()}>{originNode}</div>
          //   ),
          //   onChange: (selectedRowKeys) => setSelectedRows(selectedRowKeys),
          // }}
        />
      </Container>
    </>
  );
};
