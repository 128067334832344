import React, { FC } from 'react';

import { Button, Form, Layout, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { CatalogVisibilityRule, OrderApprovalRule } from '@app/models/products';
import { getFormErrorsFromResponse } from '@app/services/getFormErrorsFromResponse';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { PageTitle } from '@components/PageTitle';

import { FormCatalog, IFormValues } from '../../components/Form';
import { useCreateCatalogQuery } from '../../hooks';

export const Add: FC = () => {
  const commonT = useTranslation(NSKeys.common);
  const catalogsT = useTranslation(NSKeys.catalogs);
  const validationT = useTranslation(NSKeys.validation);
  const navigate = useNavigate();
  const [form] = Form.useForm<IFormValues>();
  const goBack = () => navigate('/dashboard/catalogs');

  const { mutate: createCatalog, status, failureReason } = useCreateCatalogQuery();

  React.useEffect(() => {
    if (status === 'success') {
      goBack();
    }
  }, [status]);

  const handleSubmit = async (formValues: IFormValues): Promise<void> => {
    const uids = formValues.media?.map((el) => el?.response?.uid);

    createCatalog({
      data: {
        name: formValues.name,
        cover_uid: uids?.[0] || null,
        warehouse_uid: formValues?.warehouse_uid || null,
        settings: {
          visibility_rule: formValues.visibility_rule,
          order_approval_chain_uid: formValues.order_approval_chain_uid,
          order_approval_rule: formValues.order_approval_rule,
          ...(formValues.visible_to_user_ids?.length && { visible_to_user_ids: formValues.visible_to_user_ids }),
          ...(formValues.visible_to_user_group_uids?.length && {
            visible_to_user_group_uids: formValues.visible_to_user_group_uids,
          }),
        },
      },
    });
  };

  React.useEffect(() => {
    if (failureReason) {
      const formValues = form.getFieldsValue();
      const errors = getFormErrorsFromResponse(formValues, failureReason, validationT.t);
      if (errors.length) {
        form.setFields(errors);
      }
    }
  }, [failureReason]);

  return (
    <>
      <Layout.Header>
        <Breadcrumbs />
        <Row justify="space-between" align="middle">
          <PageTitle title={catalogsT.t('titleAdd')} onClick={goBack} />
          <Space size="middle">
            <Button onClick={goBack} size="middle" name="catalog_form" id="catalog_form">
              {commonT.t('buttonCancel')}
            </Button>
            <Button disabled={status === 'loading'} type="primary" size="middle" form="catalogForm" htmlType="submit">
              {commonT.t('buttonSave')}
            </Button>
          </Space>
        </Row>
      </Layout.Header>
      <FormCatalog
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          order_approval_rule: OrderApprovalRule.NotNeeded,
          visibility_rule: CatalogVisibilityRule.Hidden,
        }}
      />
    </>
  );
};
