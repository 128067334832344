import { unstable_HistoryRouter } from 'react-router-dom';

import { IOrdersParams } from '@app/models/interfaces';
import {
  ApproveOrderParamsDTO,
  CancelOrderParamsDTO,
  ContextSchema,
  CreateOrderDTO,
  OrderExportResponseSchema,
  OrderHistorySchema,
  RecordsListGenericSchemaRetrieveOrderSchema,
  RetrieveOrderSchema,
  UpdateOrderParamsDTO,
} from '@app/models/orders';
import { handleRequest, httpClient } from '@app/services/httpClient';
import { objectToSearchParams } from '@app/services/objectToSearchParams';

import { IProps } from '../interfaces';

class Orders {
  private readonly endPoint: string;

  constructor({ baseEndPoint }: IProps) {
    this.endPoint = baseEndPoint;
  }

  async postOrder(cart_uid: string, data: CreateOrderDTO): Promise<RetrieveOrderSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/carts/${cart_uid}/create-order`, data));
  }

  async getOrders(params: IOrdersParams): Promise<RecordsListGenericSchemaRetrieveOrderSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/orders${objectToSearchParams(params)}`));
  }

  async getOrder(order_uid: string): Promise<RetrieveOrderSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/orders/${order_uid}`));
  }

  async getHistory(order_uid: string): Promise<OrderHistorySchema[]> {
    return handleRequest(httpClient.get(`${this.endPoint}/orders/${order_uid}/history`));
  }

  async cancelOrder(order_uid: string, data: CancelOrderParamsDTO): Promise<RetrieveOrderSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/orders/${order_uid}/cancel`, data));
  }

  async approveOrder(order_uid: string, data: ApproveOrderParamsDTO): Promise<RetrieveOrderSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/orders/${order_uid}/approve`, data));
  }

  async markAsNeedWork(order_uid: string, data: ApproveOrderParamsDTO): Promise<RetrieveOrderSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/orders/${order_uid}/mark-as-needs-work`, data));
  }

  async getContext(): Promise<ContextSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/context`));
  }

  async updateOrder(order_uid: string, data: UpdateOrderParamsDTO): Promise<RetrieveOrderSchema> {
    return handleRequest(httpClient.put(`${this.endPoint}/orders/${order_uid}`, data));
  }

  async exportOrders(): Promise<OrderExportResponseSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/export-orders`));
  }
}

export default Orders;
