import React from 'react';

import { Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { OrderHistorySchema } from '@app/models/orders';

import { useGetHistoryQuery } from '../../../../hooks';

import { BASE_COLUMNS } from './constants';

interface IProps {
  order_uid: string;
}

export const HistoryTable: React.FC<IProps> = (props) => {
  const orderT = useTranslation(NSKeys.order);

  const { data, isLoading, status } = useGetHistoryQuery(props.order_uid);

  return (
    <React.Fragment>
      <Typography.Title level={3} style={{ marginBottom: 20 }}>
        {orderT.t('historyTable.title')}
      </Typography.Title>
      <Table
        loading={isLoading}
        columns={[
          ...BASE_COLUMNS.map((el) => ({
            ...el,
            title: orderT.t(el.title as any),
            render:
              el.key === 'kind'
                ? (_: any, data: OrderHistorySchema) => <>{orderT.t(`historyKind.${data?.info.kind}` as any)}</>
                : el.render,
          })),
        ]}
        scroll={{ x: 'max-content' }}
        dataSource={data}
        rowKey="uid"
        pagination={false}
      />
    </React.Fragment>
  );
};
