import React from 'react';

import { theme, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { OrderStatus } from '@app/models/orders';

import { StyledTag } from './styles';

interface IProps {
  status?: OrderStatus;
}

export const Status: React.FC<IProps> = (props) => {
  const ordersT = useTranslation(NSKeys.orders);
  const { token } = theme.useToken();

  const returnStatus = (color: string, text: string): React.ReactNode => (
    <Tooltip placement="top" title={text}>
      <StyledTag $color={color}>{text}</StyledTag>
    </Tooltip>
  );

  const mapStatuses = (name?: OrderStatus): React.ReactNode => {
    switch (name) {
      case OrderStatus.NeedsWork:
        return returnStatus(token.gold4, ordersT.t('statusNeedsWork'));
      case OrderStatus.Approved:
        return returnStatus(token.green4, ordersT.t('statusАpproved'));
      case OrderStatus.Cancelled:
        return returnStatus(token.red5, ordersT.t('statusCancelled'));
      case OrderStatus.NeedsApprove:
        return returnStatus(token.blue3, ordersT.t('statusNeedsApprove'));
      default:
        return returnStatus('#d9d9d9', '-');
    }
  };

  return <React.Fragment>{mapStatuses(props.status)}</React.Fragment>;
};
