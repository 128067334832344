import React from 'react';

import { Button, Form, Layout, Row, Skeleton, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { Breadcrumbs } from '@components/Breadcrumbs';
import { HeaderTitle } from '@components/HeaderTitle';
import { Paper } from '@components/Paper';

import { Status } from '../../components/Status';
import { useGetOrderQuery, useUpdateQuery } from '../../hooks';

import { useOrderItems } from '../../providers';

import { ItemsTable } from './components/ItemsTable';
import { OrderForm } from './components/OrderForm';
import { IFormValues } from './interfaces';

export const Edit: React.FC = () => {
  const navigate = useNavigate();
  const orderT = useTranslation(NSKeys.order);
  const commonT = useTranslation(NSKeys.common);
  const params = useParams<{ id: string }>();
  const [form] = Form.useForm<IFormValues>();
  const { data: initialData, isLoading } = useGetOrderQuery(params?.id || '');
  const { mutate, isLoading: isMutating, status: mutateStatus } = useUpdateQuery();
  const { setData, data } = useOrderItems();
  const goBack = () => navigate(`/dashboard/orders/${params.id}`);

  React.useEffect(() => {
    setData(initialData);
  }, [initialData]);

  React.useEffect(() => {
    if (mutateStatus === 'success') {
      goBack();
    }
  }, [mutateStatus]);

  const handleFinish = (formValues: IFormValues) => {
    if (initialData && data && params?.id) {
      mutate({
        order_uid: params?.id,
        data: {
          version: initialData.version,
          items: data?.items?.map((item) => ({ product_uid: item.product.uid, quantity: item.quantity })) || [],
          delivery_info: {
            recipient_uid: formValues.recipient_uid,
            contact_uid: formValues.contact_uid,
            delivery_date: dayjs(formValues.delivery_date).format('YYYY-MM-DD'),
            comment: formValues.comment,
          },
        },
      });
    }
  };

  return (
    <Layout>
      <Layout.Header style={{ borderBottom: 'none' }}>
        <Breadcrumbs apiTitles={[{ id: ':ordersId', value: `${orderT.t('breadCrumbPrefix')} ${initialData?.num}` }]} />
        <Row justify="space-between" align="middle" style={{ minHeight: 64 }}>
          <HeaderTitle loading={isLoading} title={`${initialData?.num || params.id}`} onClick={goBack}>
            <Status status={initialData?.status} />
          </HeaderTitle>
          <Space>
            <Button type="primary" size="middle" form="updateOrder" key="submit" htmlType="submit" loading={isMutating}>
              {commonT.t('buttonSave')}
            </Button>
            <Button onClick={goBack} size="middle">
              {commonT.t('buttonCancel')}
            </Button>
          </Space>
        </Row>
      </Layout.Header>
      <Paper style={{ margin: '24px', padding: '24px 24px' }}>
        <Typography.Title level={3}>{orderT.t('blockInfo.title')}</Typography.Title>
        {initialData ? (
          <OrderForm
            form={form}
            onFinish={handleFinish}
            initialValues={{
              delivery_date: dayjs(initialData.delivery_info.delivery_date, 'YYYY-MM-DD'),
              contact_uid: initialData?.delivery_info?.contact?.uid || '',
              recipient_uid: initialData?.delivery_info?.recipient?.uid || '',
              recipient_address: initialData?.delivery_info?.recipient?.address?.as_string || '',
              phone: initialData?.delivery_info?.contact?.phone || '',
              comment: initialData?.delivery_info?.comment,
            }}
          />
        ) : (
          <Skeleton.Input style={{ margin: '20px 0' }} />
        )}

        <ItemsTable orderTotal={initialData?.total} />
      </Paper>
    </Layout>
  );
};
