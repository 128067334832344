import React from 'react';

import { Space, Table, Typography } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { OrderItemSchema, RetrieveOrderSchema } from '@app/models/orders';

import { columns } from './data/constants';

interface IProps {
  isLoading: boolean;
  data?: RetrieveOrderSchema;
}

export const ItemsTable: React.FC<IProps> = (props) => {
  const orderT = useTranslation(NSKeys.order);

  return (
    <React.Fragment>
      <Typography.Title level={3} style={{ marginBottom: 20 }}>
        {orderT.t('itemsTable.title', { count: props.data?.total_items })}
      </Typography.Title>
      <Table<OrderItemSchema>
        loading={props.isLoading}
        columns={columns(orderT.t)}
        dataSource={props.data?.items || undefined}
        pagination={false}
        rowKey="uid"
        scroll={{ x: 'max-content' }}
        style={{ marginBottom: 20 }}
        summary={() => (
          <Table.Summary>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={3}>
                {orderT.t('itemsTable.total')}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} align="right">
                {props.data?.total?.volume}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} align="right">
                {props.data?.total?.weight}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} align="center">
                {props.data?.total?.quantity}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} align="right">
                {props.data?.total?.price}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </React.Fragment>
  );
};
