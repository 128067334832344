/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Address */
export interface Address {
  /** Uid */
  uid: string;
  /** As String */
  as_string: string;
  /** Country Name */
  country_name?: string;
  /** Country Iso Code */
  country_iso_code?: string;
  /** Region Name */
  region_name?: string;
  /** City Name */
  city_name?: string;
  /** Postal Code */
  postal_code?: string;
  /** Street Name */
  street_name?: string;
  /** Street Number */
  street_number?: string | null;
  /** Place Name */
  place_name?: string | null;
  /** Lat */
  lat?: number | null;
  /** Lon */
  lon?: number | null;
}

/** ApprovalRouteSchema */
export interface ApprovalRouteSchema {
  /** Is Finished */
  is_finished: boolean;
  /** Steps */
  steps: ApprovalStepSchema[];
}

/** ApprovalStepSchema */
export interface ApprovalStepSchema {
  user_group: UserGroupSchema;
  /** Is Passed */
  is_passed: boolean;
  /** Is Current */
  is_current: boolean;
}

/** ApproveOrderParamsDTO */
export interface ApproveOrderParamsDTO {
  /**
   * Version
   * Текущая версия заказа
   */
  version: string;
  /** Comment */
  comment: string;
}

/** CancelOrderParamsDTO */
export interface CancelOrderParamsDTO {
  /**
   * Version
   * Текущая версия заказа
   */
  version: string;
  /** Comment */
  comment: string;
}

/** CatalogSchema */
export interface CatalogSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  cover: Image | null;
}

/** Contact */
export interface Contact {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Email */
  email: string | null;
  /** Phone */
  phone: string;
}

/** ContextSchema */
export interface ContextSchema {
  scope: ContextScopeSchema;
}

/** ContextScopeSchema */
export interface ContextScopeSchema {
  /** ScopePermissions */
  $permissions: {
    /** View Orders */
    view_orders?: boolean;
    /** Export Orders */
    export_orders?: boolean;
  };
}

/** CreateDeliveryInfo */
export interface CreateDeliveryInfo {
  /**
   * Recipient Uid
   * Получатель
   */
  recipient_uid: string;
  /**
   * Contact Uid
   * Контактная информация
   */
  contact_uid: string;
  /**
   * Delivery Date
   * Дата доставки
   * @format date
   */
  delivery_date: string;
  /**
   * Comment
   * Комментарий
   * @default ""
   */
  comment?: string;
}

/** CreateOrderDTO */
export interface CreateOrderDTO {
  delivery_info: CreateDeliveryInfo;
}

/** DeliveryInfo */
export interface DeliveryInfo {
  /** Получатель */
  recipient: Recipient;
  /** Контактная информация */
  contact: Contact;
  /** Информация по адресу */
  address: Address;
  /**
   * Delivery Date
   * Дата доставки
   * @format date
   */
  delivery_date: string;
  /**
   * Comment
   * Комментарий
   */
  comment: string;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** HistoryOrderStateSchema */
export interface HistoryOrderStateSchema {
  delivery_info: DeliveryInfo;
  order_total: OrderTotal;
}

/** Image */
export interface Image {
  /** Uid */
  uid: string;
  /** Variants */
  variants: Record<string, ImageVariant>;
}

/** ImageVariant */
export interface ImageVariant {
  /** Url */
  url: string;
}

/** InvalidOrderApprovalStepError */
export interface InvalidOrderApprovalStepError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'order_invalid_approval_step';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** InvalidOrderStatusError */
export interface InvalidOrderStatusError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'order_invalid_status';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** MarkOrderAsNeedsWorkParamsDTO */
export interface MarkOrderAsNeedsWorkParamsDTO {
  /**
   * Version
   * Текущая версия заказа
   */
  version: string;
  /** Comment */
  comment: string;
}

/** OrderApprovalChainSchema */
export interface OrderApprovalChainSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
}

/** OrderApprovedHistoryInfoSchema */
export interface OrderApprovedHistoryInfoSchema {
  /**
   * Kind
   * @default "approved"
   */
  kind?: 'approved';
  /** Comment */
  comment: string | null;
}

/** OrderCancelledHistoryInfoSchema */
export interface OrderCancelledHistoryInfoSchema {
  /**
   * Kind
   * @default "cancelled"
   */
  kind?: 'cancelled';
  /** Comment */
  comment: string | null;
}

/**
 * OrderCategory
 * * `all` - все'
 * * `mine` - мои'.
 * * `to_approve` - на согласование'.
 * * `approved_by_me` - согласованные'
 */
export enum OrderCategory {
  All = 'all',
  Mine = 'mine',
  ToApprove = 'to_approve',
  ApprovedByMe = 'approved_by_me',
}

/** OrderCreatedHistoryInfoSchema */
export interface OrderCreatedHistoryInfoSchema {
  /**
   * Kind
   * @default "created"
   */
  kind?: 'created';
  order: HistoryOrderStateSchema;
}

/** OrderExportResponseSchema */
export interface OrderExportResponseSchema {
  /**
   * Filename
   * Имя файла экспорта отчетов
   */
  filename: string;
}

/** OrderHistorySchema */
export interface OrderHistorySchema {
  /** Uid */
  uid: string;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  user: UserSchema;
  order_status: OrderStatus;
  /** Info */
  info:
    | ({
        kind: 'approved';
      } & OrderApprovedHistoryInfoSchema)
    | ({
        kind: 'cancelled';
      } & OrderCancelledHistoryInfoSchema)
    | ({
        kind: 'created';
      } & OrderCreatedHistoryInfoSchema)
    | ({
        kind: 'marked_as_needs_work';
      } & OrderMarkedAsNeedsWorkHistoryInfoSchema)
    | ({
        kind: 'updated';
      } & OrderUpdatedHistoryInfoSchema);
}

/** OrderItemSchema */
export interface OrderItemSchema {
  /** Uid */
  uid: string;
  /** Quantity */
  quantity: number;
  product: SimpleProductSchema;
  /** Volume */
  volume: string;
  /** Price */
  price: string;
  /** Weight */
  weight: string;
}

/** OrderMarkedAsNeedsWorkHistoryInfoSchema */
export interface OrderMarkedAsNeedsWorkHistoryInfoSchema {
  /**
   * Kind
   * @default "marked_as_needs_work"
   */
  kind?: 'marked_as_needs_work';
  /** Comment */
  comment: string | null;
}

/** OrderOperations */
export interface OrderOperations {
  /** Approve */
  approve: boolean;
  /** Cancel */
  cancel: boolean;
  /** Mark As Needs Work */
  mark_as_needs_work: boolean;
  /** Update */
  update: boolean;
}

/** OrderStatus */
export enum OrderStatus {
  NeedsApprove = 'needs_approve',
  NeedsWork = 'needs_work',
  Approved = 'approved',
  Cancelled = 'cancelled',
}

/** OrderTotal */
export interface OrderTotal {
  /**
   * Quantity
   * @default 0
   */
  quantity?: number;
  /**
   * Volume
   * @default "0"
   */
  volume?: string;
  /**
   * Price
   * @default "0"
   */
  price?: string;
  /**
   * Weight
   * @default "0"
   */
  weight?: string;
}

/** OrderUpdatedHistoryInfoSchema */
export interface OrderUpdatedHistoryInfoSchema {
  /**
   * Kind
   * @default "updated"
   */
  kind?: 'updated';
  before: HistoryOrderStateSchema;
  after: HistoryOrderStateSchema;
}

/** OrderVersionMismatchError */
export interface OrderVersionMismatchError {
  /** Error Id */
  error_id: string;
  /**
   * Title
   * @default null
   */
  title?: string | null;
  /**
   * Detail
   * @default null
   */
  detail?: string | null;
  /** Code */
  code?: 'order_version_mismatch';
  /**
   * Status Code
   * @default null
   */
  status_code?: number | null;
  /** @default null */
  source?: null;
  /**
   * Meta
   * @default null
   */
  meta?: object | null;
}

/** Recipient */
export interface Recipient {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
  /** Code */
  code: string | null;
  address: Address;
}

/** RecordsListGenericSchema[RetrieveOrderSchema] */
export interface RecordsListGenericSchemaRetrieveOrderSchema {
  /** Items */
  items: RetrieveOrderSchema[];
  /**
   * Total
   * @default 0
   */
  total?: number;
}

/** RetrieveOrderSchema */
export interface RetrieveOrderSchema {
  /** Uid */
  uid: string;
  /** Num */
  num: number;
  /** Version */
  version: string;
  status: OrderStatus;
  $operations: OrderOperations;
  /**
   * Created At
   * @format date-time
   */
  created_at: string;
  /** Updated At */
  updated_at: string | null;
  /** Approved At */
  approved_at: string | null;
  /** Cancelled At */
  cancelled_at: string | null;
  author: UserSchema;
  catalog: CatalogSchema;
  delivery_info: DeliveryInfo;
  total: OrderTotal;
  /** Total Items */
  total_items: number;
  order_approval_chain: OrderApprovalChainSchema | null;
  approval_route: ApprovalRouteSchema | null;
  /** Items */
  items: OrderItemSchema[] | null;
  /** OrderPermissions */
  $permissions: {
    /** Approve */
    approve?: boolean;
    /** Cancel */
    cancel?: boolean;
    /** Mark As Needs Work */
    mark_as_needs_work?: boolean;
    /** View */
    view?: boolean;
    /** Update */
    update?: boolean;
  };
}

/** SimpleProductSchema */
export interface SimpleProductSchema {
  /** Uid */
  uid: string;
  /**
   * Title
   * Название
   */
  title: string;
  /**
   * Code
   * Артикул
   */
  code: string;
  /**
   * Price
   * Стоимость
   */
  price: string | null;
  /**
   * Package Amount
   * Кратность упаковки
   */
  package_amount: number | null;
}

/** UpdateDeliveryInfo */
export interface UpdateDeliveryInfo {
  /**
   * Recipient Uid
   * Получатель
   */
  recipient_uid: string;
  /**
   * Contact Uid
   * Контактная информация
   */
  contact_uid: string;
  /**
   * Delivery Date
   * Дата доставки
   * @format date
   */
  delivery_date: string;
  /**
   * Comment
   * Комментарий
   */
  comment: string;
}

/** UpdateOrderItemDTO */
export interface UpdateOrderItemDTO {
  /** Product Uid */
  product_uid: string;
  /**
   * Quantity
   * @min 1
   */
  quantity: number;
}

/** UpdateOrderParamsDTO */
export interface UpdateOrderParamsDTO {
  /**
   * Version
   * Текущая версия заказа
   */
  version: string;
  /** Items */
  items: UpdateOrderItemDTO[];
  delivery_info: UpdateDeliveryInfo;
}

/** UserGroupSchema */
export interface UserGroupSchema {
  /** Uid */
  uid: string;
  /** Name */
  name: string;
}

/** UserSchema */
export interface UserSchema {
  /** Id */
  id: number;
  /** Username */
  username: string;
  /** Name */
  name: string;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** _Source */
export interface Source {
  /** Pointer */
  pointer?: string | null;
  /** Header */
  header?: string | null;
  /** Parameter */
  parameter?: string | null;
}
