import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrderCategory, OrderStatus } from '@app/models/orders';

export interface OrdersState {
  catalog_uid?: string;
  category: OrderCategory;
  author_id?: number;
  status?: OrderStatus;
  created_at?: string;
}

const initialState: OrdersState = {
  category: OrderCategory.All,
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setValue(state, { payload }: PayloadAction<Partial<OrdersState>>) {
      Object.assign(state, payload);
    },
    reset: () => initialState,
  },
});

export const setFilters = createAsyncThunk<void, Partial<OrdersState>>('SET_FILTERS', async (payload, { dispatch }) => {
  dispatch(
    setValue({
      ...initialState,
      ...payload,
    })
  );
});

export const {
  reducer,
  actions: { setValue, reset },
} = ordersSlice;

export default reducer;
